import React from 'react';
import { marketConfig } from 'app/slices/config';
import { BaseOffer, OfferTemplates } from 'utils/types/offers';
import {
  OfferVersion,
  OffersGridItemFooter,
  OffersGridItemHeader,
  OffersGridItemImage,
  OffersGridItemImageContainer,
  OffersGridItemTemplate,
  StyledStatusLabel,
  NoOfferText,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerVersionItem/OfferVersionItem.style';
import { OfferVersionItemProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerVersionItem/OfferVersionItem.consts';
import { Modals, openModal, modal as modalSlice } from 'app/slices/modals';
import { FormMode } from 'utils/types';
import { store } from 'app/store';
import offersGqls from 'pages/offers/Offers.gqls';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  OffersGridItemActions,
  OffersGridItemBackdrop,
  StyledButtonText,
  StyledView,
} from 'pages/offers/components/offerItem/OfferItem.style';
import { NoImageText } from 'pages/offers/Offers.style';
import { FetchPolicies } from 'utils/types/common';
const OfferVersionItem = ({ offer, campaign, onClick, className }: OfferVersionItemProps) => {
  const { props, data: modalData } = useSelector(modalSlice);
  const [load] = useLazyQuery(offersGqls.queries.getById, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: offer?.baseOfferId,
    },
  });

  const { data: realoffer } = useQuery(offersGqls.queries.getById, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: offer?.baseOfferId,
    },
  });
  const { config } = useSelector(marketConfig);
  const primaryTranslation = realoffer?.getOffer?.versions[0]?.translationsMap[config.primaryLanguage];

  return (
    <OfferVersion
      className={className}
      onClick={onClick}
      offer={offer}
      campaign={campaign}
      data-automation-id="offer-version"
    >
      {offer ? (
        <>
          <OffersGridItemBackdrop>
            <OffersGridItemActions>
              {modalData.clickSelectOffer === true ? (
                <StyledButtonText
                  onClick={() => {
                    load().then(({ data }) => {
                      store.dispatch(
                        openModal({
                          modal: Modals.OfferModal,
                          data: {
                            viewOnly: true,
                            campaign: { ...props.campaign },
                          },
                          props: {
                            offer: data.getOffer,
                            campaign: { ...props.campaign },
                            viewOnly: true,
                            mode: FormMode.View,
                            onClose: () =>
                              modalData.campaign
                                ? store.dispatch(
                                    openModal({
                                      modal: Modals.OfferSelectionModal,
                                      data: {
                                        ...modalData,
                                      },
                                      props: {
                                        ...modalData,
                                        onSave: (offerVersion: BaseOffer) =>
                                          store.dispatch(
                                            openModal({
                                              modal: Modals.CampaignModal,
                                              data: {
                                                mode: modalData.mode,
                                                campaign: { ...modalData.campaign, offerVersion },
                                                clickSelectOffer: false,
                                              },
                                              props: {
                                                mode: modalData.mode,
                                                campaign: { ...modalData.campaign, offerVersion },
                                                clickSelectOffer: false,
                                              },
                                            }),
                                          ),
                                        onCancel: () => {
                                          store.dispatch(
                                            openModal({
                                              modal: Modals.CampaignModal,
                                              data: {
                                                mode: modalData.mode,
                                                campaign: {
                                                  ...modalData.campaign,
                                                  ...props.campaign,
                                                },
                                                clickSelectOffer: false,
                                              },
                                              props: {
                                                mode: modalData.mode,
                                                campaign: {
                                                  ...modalData.campaign,
                                                  ...props.campaign,
                                                },
                                                clickSelectOffer: false,
                                              },
                                            }),
                                          );
                                        },
                                      },
                                    }),
                                  )
                                : store.dispatch(
                                    openModal({
                                      modal: Modals.CampaignModal,
                                      props,
                                    }),
                                  ),
                          },
                        }),
                      );
                    });
                  }}
                  data-title="View"
                >
                  <StyledView name="view" />
                </StyledButtonText>
              ) : (
                <StyledButtonText
                  onClick={() => {
                    load().then(({ data }) => {
                      store.dispatch(
                        openModal({
                          modal: Modals.OfferModal,
                          data: {
                            viewOnly: true,
                            campaign: {
                              ...props.campaign,
                              campaign,
                            },
                          },
                          props: {
                            offer: data.getOffer,
                            campaign: { ...props.campaign },
                            viewOnly: true,
                            mode: FormMode.View,
                            onClose: () =>
                              store.dispatch(
                                openModal({
                                  modal: Modals.CampaignModal,
                                  props: { mode: props.mode, campaign },
                                  data: { mode: modalData.mode, campaign },
                                }),
                              ),
                          },
                        }),
                      );
                    });
                  }}
                  data-title="View"
                >
                  <StyledView name="view" />
                </StyledButtonText>
              )}
            </OffersGridItemActions>
          </OffersGridItemBackdrop>
          <OffersGridItemHeader>
            <StyledStatusLabel type={offer.status} />
            <OffersGridItemTemplate>
              {OfferTemplates[offer.templateValues.templateType as keyof typeof OfferTemplates]?.name}
            </OffersGridItemTemplate>
            <span>{`ID ${offer?.baseOfferId}`}</span>
          </OffersGridItemHeader>
          <OffersGridItemImageContainer>
            {primaryTranslation?.image ? (
              <OffersGridItemImage
                src={`${primaryTranslation?.image?.file}`}
                alt={`${primaryTranslation?.image?.name}`}
                loading="lazy"
                crossOrigin="anonymous"
              />
            ) : (
              <NoImageText>No Image Defined</NoImageText>
            )}
          </OffersGridItemImageContainer>
          <OffersGridItemFooter>
            <h6 title={primaryTranslation?.title}>{primaryTranslation?.title}</h6>
            <p title={primaryTranslation?.subtitle}>{primaryTranslation?.subtitle}</p>
          </OffersGridItemFooter>
        </>
      ) : (
        <NoOfferText>No Offer Defined</NoOfferText>
      )}
    </OfferVersion>
  );
};

export default OfferVersionItem;
