import { ApprovalStatus } from '../types';
import { BaseApproval } from './offers';

export interface BaseCampaignApproval extends BaseApproval {
  id?: number;
  comment?: string;
  deploymentFailedCount?: number;
}

export enum CampaignType {
  LocationBased = 'locationBased',
  // GuestBrowseLBO = 'guestBrowseLBO',
  Segmented = 'segmented',
  Voucher = 'voucher',
  CustomerServiceOffer = 'customerServiceOffer',
  // - when adding back remember to add to ExpirationDate type array
  WelcomeOffer = 'welcomeOffer',
  Loyalty = 'loyalty',
  Ned = 'ned',
  LoyaltyExternalVoucher = 'loyaltyExternalVoucher'
  // PunchCard = 'punchCard', // OMS-520 - Hide "Punch Card" references from UI
}

export const CampaignTypeLabel: Record<CampaignType, string> = {
  [CampaignType.LocationBased]: 'Location Based',
  // [CampaignType.GuestBrowseLBO]: 'Guest Browse LBO',
  [CampaignType.Segmented]: 'Segmented',
  [CampaignType.Voucher]: 'Voucher',
  [CampaignType.CustomerServiceOffer]: 'Customer Service Offer',
  [CampaignType.WelcomeOffer]: 'Welcome Offer',
  [CampaignType.Loyalty]: 'Loyalty',
  [CampaignType.Ned]: 'NED',
  [CampaignType.LoyaltyExternalVoucher]: 'Loyalty External Voucher'
  // [CampaignType.PunchCard]: 'Punch Card ', // OMS-520 - Hide "Punch Card" references from UI
};

export interface VoucherGroup {
  voucherGroupId: number;
  startAtUtc: string;
  endAtUtc: string
  availableCount: number;
}

export enum CampaignAlert {
  UpdateFailed = 'updateFailed',
  RevokeFailed = 'revokeFailed',
  StopAssociationFailed = 'stopAssociationFailed',
  Conflicted = 'conflicted',
}

export const CampaignAlertLabel: Record<CampaignAlert, string> = {
  [CampaignAlert.UpdateFailed]: 'Update failed',
  [CampaignAlert.RevokeFailed]: 'Revoke failed',
  [CampaignAlert.StopAssociationFailed]: 'Stop Association failed',
  [CampaignAlert.Conflicted]: 'Conflicted',
};

export const CampaignApprovalStatuses = [
  ApprovalStatus.Draft,
  ApprovalStatus.PendingApproval,
  ApprovalStatus.Approved,
  ApprovalStatus.Deployed,
  ApprovalStatus.Active,
  ApprovalStatus.AssociationStopped,
  ApprovalStatus.Expired,
  ApprovalStatus.Revoked,
  ApprovalStatus.DeploymentFailed,
  ApprovalStatus.Rejected,
  ApprovalStatus.Archived,
];

export enum LockableProcessMessage {
  BulkSubmitForApproval = 'bulkSubmitForApprovalInProgress',
  BulkApproval = 'bulkApprovalInProgress',
}
