import { gql } from '@apollo/client';

const offersGqls = {
  queries: {
    getOffersScreenData: gql`
      query Offers($data: GetAllQueryDto) {
        getOffers(data: $data) {
          items {
            ... on BaseOffer {
              id
              templateType
              isArchive
              isLocked
              segmentId
              tags {
                id
                name
              }
              versions {
                id
                baseOfferId
                status
                translationsMap
                translations {
                  title
                  posTitle
                  subtitle
                  description
                  language
                  image {
                    fileName
                    file
                    id
                  }
                }
                isDraft
              }
            }
          }
          total
        }
      }
    `,
    getAll: gql`
      query Offers($data: GetAllQueryDto) {
        getOffers(data: $data) {
          items {
            ... on BaseOffer {
              id
              templateType
              isArchive
              isLocked
              segmentId
              tags {
                id
                name
              }
              versions {
                id
                baseOfferId
                status
                updatedBy {
                  id
                }
                approvals {
                  id
                  approvalStatus
                  comment
                }
                templateValues
                term {
                  id
                  name
                  translationsMap
                  originalTermId
                }
                translationsMap
                translations {
                  title
                  posTitle
                  subtitle
                  description
                  language
                  image {
                    fileName
                    file
                    id
                  }
                }
                isDraft
                pointOfDistribution
              }
              updatedAt
              createdBy {
                id
              }
            }
          }
          total
        }
      }
    `,
    getOffersIntersection: gql`
      query OffersIntersection($data: GetAllQueryDto) {
        getOffersIntersection(data: $data) {
          items {
            ... on BaseOffer {
              id
              templateType
              isArchive
              isLocked
              tags {
                id
                name
              }
              versions {
                id
                baseOfferId
                status
                updatedBy {
                  id
                }
                approvals {
                  id
                  approvalStatus
                  comment
                }
                templateValues
                term {
                  id
                  name
                  translationsMap
                  originalTermId
                }
                translationsMap
                translations {
                  title
                  posTitle
                  subtitle
                  description
                  language
                  image {
                    fileName
                    file
                    id
                  }
                }
                isDraft
                pointOfDistribution
              }
              updatedAt
              createdBy {
                id
              }
            }
          }
        }
      }
    `,
    getForImpact: gql`
      query Offers($data: GetAllQueryDto) {
        getOffers(data: $data) {
          items {
            ... on BaseOffer {
              id
              templateType
              versions {
                id
                status
                translationsMap
                translations {
                  title
                  image {
                    id
                  }
                }
                templateValues
                term {
                  id
                  originalTermId
                }
              }
            }
          }
          total
        }
      }
    `,
    getById: gql`
      query Offer($id: BigInt!) {
        getOffer(id: $id) {
          ... on BaseOffer {
            id
            templateType
            isArchive
            isLocked
            segmentId
            tags {
              id
              name
            }
            versions {
              id
              baseOfferId
              status
              updatedBy {
                id
              }
              approvals {
                id
                approvalStatus
                comment
              }
              templateValues
              term {
                id
                name
                translationsMap
                originalTermId
              }
              translationsMap
              translations {
                title
                posTitle
                subtitle
                description
                language
                image {
                  fileName
                  file
                  id
                }
              }
              isDraft
              pointOfDistribution
            }
            updatedAt
            createdBy {
              id
            }
            inProgress
          }
        }
      }
    `,
    getAllVoucherGroups: gql`
      query VoucherGroups {
        getAllVoucherGroups {
          id
          voucherGroupId
          voucherGroupName
          vendorName
          vendorExchangeUrl
          vendorExternalId
          startAtUtc
          endAtUtc
          tags
          comment
          active
          availableCount
          createdAt
          updatedAt
        }
      }
    `,
    getVoucherGroupById: gql`
      query VoucherGroup($id: Int!) {
        getVoucherGroupById(id: $id) {
          voucherGroupId
          startAtUtc
          endAtUtc
          availableCount
        }
      }
    `,
  },
  mutations: {
    add: gql`
      mutation CreateOffer($data: CreateOfferDto!) {
        createOffer(data: $data) {
          id
          versions {
            id
          }
        }
      }
    `,
    update: gql`
      mutation UpdateOffer($data: UpdateOfferDto!) {
        updateOffer(data: $data) {
          id
          versions {
            id
          }
          isArchive
        }
      }
    `,
    createApproval: gql`
      mutation CreateApproval($data: CreateOfferApprovalDto!) {
        createApproval(data: $data) {
          id
        }
      }
    `,
    updateApproval: gql`
      mutation UpdateApproval($data: UpdateOfferApprovalDto!) {
        updateApproval(data: $data) {
          id
        }
      }
    `,
    updateLocationSetOffers: gql`
      mutation UpdateLocationSetOffers($data: UpdateLocationSetOffersDto!) {
        updateLocationSetOffers(data: $data)
      }
    `,
  },
};

export default offersGqls;
