import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { ApprovalStatus, DateTimeConfig, FormMode } from 'utils/types';
import Error from 'components/shared/error/Error';
import { prepareCampaignForUpdate, prepareNewCampaignForSave } from 'utils/api/campaigns';
import { isInArray } from 'utils/array';
import { StyledFormSection } from 'pages/shared/shared.style';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { convertUtcDateToTimezoneDate, convertUtcDateToTimezoneDateUsingLibrary } from 'utils/date';
import LocalCampaignSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/localCampaignSection/LocalCampaignSection';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { store } from 'app/store';
import { offersSelection } from 'app/genericSlices/offers';
import { modal as modalSlice } from 'app/slices/modals';
import { OfferFilters } from 'pages/offers/Offers.const';
import {
  CampaignFormProps,
  FormTitle,
  SectionTitle,
} from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.consts';
import {
  FormContainer,
  StyledModal,
  StyledSectionTitleWithLoader,
} from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.style';
import CampaignFormSubtitle from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignStatusLabel/CampaignFormSubtitle';
import OfferSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSection/OfferSection';
import GeneralDetailsSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/generalDetailsSection/GeneralDetailsSection';
import CampaignFormFooter from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignFormFooter/CampaignFormFooter';
import { CampaignCreationProps, CampaignProps, FormSection } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import LocationSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/locationSection/LocationSection';
import ScheduleSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/scheduleSection/ScheduleSection';
import {
  createCampaign,
  createCampaigns,
  editCampaign,
} from 'pages/campaigns/campaignManagement/components/campaignForm/utils/CampaignActions';
import useFeatureFlag from 'hooks/use-feature-flag';
import { Feature } from 'utils/types/features';
import { hideTooltip } from 'utils/tooltip';
import ReactTooltip from 'react-tooltip';
import { LockableProcessMessage } from 'utils/types/campaigns';
import { Loader } from 'components/shared/loader';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { useVoucherState } from 'hooks/use-voucher-state';

const CampaignForm = ({ mode, campaign }: CampaignFormProps) => {
  const { config } = useSelector(marketConfig);
  const { data: modalData } = useSelector(modalSlice);
  const { dateFormat, timeFormat, startTimezone, endTimezone, calendarWeekStartsOn } = config;
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: cloneDeep(
      omitBy(
        {
          ...campaign,
          schedule: campaign?.schedule
            ? {
                ...campaign.schedule,
                campaignStart: convertUtcDateToTimezoneDateUsingLibrary(campaign.schedule.campaignStart, startTimezone),
                campaignEnd: convertUtcDateToTimezoneDateUsingLibrary(campaign.schedule.campaignEnd, endTimezone),
              }
            : undefined,
          push_notification:
            campaign?.push_notification &&
            campaign?.push_notification?.startDate !== '' &&
            campaign?.push_notification?.push_notification_translation?.length > 0
              ? {
                  startDate: convertUtcDateToTimezoneDate(campaign?.push_notification?.startDate, startTimezone),
                  push_notification_translation: (campaign?.push_notification?.push_notification_translation || []).map(
                    (translation: any) => ({
                      title: translation?.title,
                      message: translation?.message,
                      language: translation?.language,
                    }),
                  ),
                }
              : null,
        },
        isNil,
      ),
    ) as any,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const voucherState = useVoucherState();

  const isDisabled =
    mode === FormMode.View ||
    (mode === FormMode.Edit &&
      isInArray([ApprovalStatus.Active, ApprovalStatus.Deployed, ApprovalStatus.AssociationStopped], campaign?.status));
  const dateTimeConfig: DateTimeConfig = useMemo(
    () => ({ dateFormat, timeFormat, startTimezone, endTimezone, calendarWeekStartsOn }),
    [dateFormat, timeFormat, startTimezone, endTimezone, calendarWeekStartsOn],
  );
  formMethods.register('offerVersion', { required: true });

  const FormSectionToComponent: Record<FormSection, JSX.Element> = useMemo(
    () => ({
      [FormSection.Offer]: (
        <OfferSection mode={mode} isDisabled={isDisabled} isExternalVoucher={voucherState.isExternalVoucher} />
      ),
      [FormSection.GeneralDetails]: <GeneralDetailsSection mode={mode} isDisabled={isDisabled} />,
      [FormSection.Schedule]: (
        <ScheduleSection
          mode={mode}
          status={campaign?.status}
          isDisabled={isDisabled}
          dateTimeConfig={dateTimeConfig}
          voucherState={voucherState}
        />
      ),
      [FormSection.Locations]: voucherState.isExternalVoucher ? null : (
        <LocationSection mode={mode} campaign={campaign} isDisabled={isDisabled} />
      ),
    }),
    [mode, isDisabled, voucherState, dateTimeConfig],
  );

  const getModalTitle = () => `${FormTitle[mode]} ${campaign?.externalId ? ` ID ${campaign.externalId}` : ''}`;
  const isCampaignBulkOn = useFeatureFlag(Feature.CampaignBulk);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleEditCampaign = async (formData: any, isDraft: boolean, startTimezone1: string, endTimezone1: string) => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
      const campaignToSave = prepareCampaignForUpdate(
        formData,
        isDraft,
        startTimezone1,
        endTimezone1,
        voucherState.isExternalVoucher,
      );
      await editCampaign(campaignToSave, formData.alert);
      hideTooltip('#deployed-active-tooltip');
      ReactTooltip.hide();
    }
  };

  const onSubmit = (isDraft = false) => {
    return async (formData: any) => {
      store.dispatch(offersSelection.actions.setFilter({ filter: [OfferFilters.Zone], value: null }));
      setIsSubmitting(true);
      if (mode === FormMode.New && isCampaignBulkOn && formData.schedule.dates?.length) {
        const bulkCampaignToSave = prepareNewCampaignForSave(formData, isDraft, startTimezone, endTimezone, true);
        await createCampaigns(bulkCampaignToSave as CampaignCreationProps);
      } else if (mode === FormMode.New || mode === FormMode.Duplicate) {
        const campaignToSave = prepareNewCampaignForSave(
          formData,
          isDraft,
          startTimezone,
          endTimezone,
          false,
          voucherState.isExternalVoucher,
        );
        await createCampaign(campaignToSave as CampaignProps);
      } else if (mode === FormMode.Edit) {
        handleEditCampaign(formData, isDraft, startTimezone, endTimezone);
      }
      setIsSubmitting(false);
    };
  };

  const renderedFormSections = useMemo(() => {
    return Object.values(FormSection).map((section) => {
      const component = FormSectionToComponent[section];
      if (component) {
        return (
          <StyledFormSection key={section}>
            <StyledSectionTitleWithLoader>
              {SectionTitle[section]}
              {section === FormSection.Schedule && voucherState.voucherApiLoading && <Loader size={LoaderSize.Small} />}
              {section === FormSection.Schedule && (
                <Error name="schedule.exchangeLimit" errors={voucherState.voucherError} />
              )}
            </StyledSectionTitleWithLoader>
            {component}
          </StyledFormSection>
        );
      }
      return null;
    });
  }, [FormSectionToComponent, voucherState]);

  return (
    <StyledModal
      title={getModalTitle()}
      subtitle={
        campaign?.status && (
          <CampaignFormSubtitle
            status={campaign?.status}
            alert={campaign?.alert}
            comment={campaign?.approvals[0]?.comment}
            isTriggerEvent={campaign?.isTriggerEvent}
          />
        )
      }
      isLocked={campaign?.isLocked}
      withLoader
      ignoreOperations={['PeriodsForZone', 'Tags', 'Offer', 'VoucherGroup']}
      {...(campaign?.inProgress && {
        inProgress: true,
        message:
          campaign?.inProgress === LockableProcessMessage.BulkSubmitForApproval
            ? 'Campaign submission for approval is in progress'
            : 'Campaign approval is in progress',
      })}
      onHover
    >
      <FormProvider {...formMethods}>
        <FormContainer>
          {!voucherState.isExternalVoucher && (
            <MarketConfigurationGuard
              configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
            >
              <LocalCampaignSection mode={mode} isDisabled={isDisabled} />
            </MarketConfigurationGuard>
          )}
          {renderedFormSections}
        </FormContainer>
        <Error name="campaign.offerVersion" errors={formMethods.formState.errors} />
        <Error name="campaign.restaurantEligibility.restaurants" errors={formMethods.formState.errors} />
        <Error name="campaign.restaurantEligibility.restaurantGroups" errors={formMethods.formState.errors} />
        <CampaignFormFooter
          mode={mode}
          campaignStatus={campaign?.status}
          alert={campaign?.alert}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          isLocked={campaign?.isLocked || false}
          isDraft={campaign?.isDraft}
          onSubmit={(isDraft: boolean) => formMethods.handleSubmit(onSubmit(isDraft))}
          fromCalendar={modalData.fromCalendar}
          dateTimeConfig={dateTimeConfig}
          voucherState={voucherState}
          {...(campaign?.inProgress && { inProgress: true })}
        />
      </FormProvider>
    </StyledModal>
  );
};

export default CampaignForm;
