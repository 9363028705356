import { DistributionType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { ApprovalStatus } from 'utils/types';
import { CampaignAlert, CampaignType } from 'utils/types/campaigns';

export const getIsApproveDisabled = (
  isFormValid: boolean,
  campaignStatus?: ApprovalStatus,
  alert?: CampaignAlert,
  voucherError?: any,
) => {
  return (
    (campaignStatus === ApprovalStatus.PendingApproval && alert && alert === CampaignAlert.Conflicted) ||
    !isFormValid ||
    !!voucherError
  );
};

export const getHasRevokeButton = (
  campaignType?: CampaignType,
  campaignVoucherDistribution?: DistributionType,
  campaignStatus?: ApprovalStatus,
) => {
  return !(
    campaignType === CampaignType.Loyalty ||
    (campaignType === CampaignType.Voucher && campaignVoucherDistribution === DistributionType.Print) ||
    (campaignType === CampaignType.Voucher && campaignStatus !== ApprovalStatus.Deployed) ||
    campaignType === CampaignType.LoyaltyExternalVoucher
  );
};

export const getSubmitForApprovalButtonDisabled = (isFormValid: boolean, offerVersion?: any, voucherError?: any) => {
  return !isFormValid || (offerVersion && offerVersion.status !== ApprovalStatus.Approved) || !!voucherError;
};
