import React, { useState } from 'react';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { useSelector } from 'react-redux';
import { ApprovalStatus, OrderDirection } from 'utils/types';
import { BaseOffer } from 'utils/types/offers';
import { OfferFilters } from 'pages/offers/Offers.const';
import { StyledLoader } from 'pages/shared/shared.style';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { offersSelection } from 'app/genericSlices/offers';
import offersGqls from 'pages/offers/Offers.gqls';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import { store } from 'app/store';
import {
  NoOffers,
  OffersContainer,
  OfferSelectionContainer,
  OfferSelectionFooter,
  OffersList,
  StyledModal,
  StyledOfferItem,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSelection/OfferSelection.style';
import OfferSelectionFilterBar
  from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSelection/components/offerSelectionFilterBar/OfferSelectionFilterBar';
import { OfferSelectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSelection/OfferSelection.consts';
import { FetchPolicies } from 'utils/types/common';

const OfferSelection = ({ selectedOffer, onSave, onCancel, externalVoucherCampaign = false }: OfferSelectionProps) => {
  const [selected, setSelected] = useState(selectedOffer);
  let { filters } = useSelector(offersSelection.offersState);
  if (filters.zone && filters.zone.length > 0 && typeof filters.zone[0] === 'object') {
    filters = {
      ...filters,
      zone: filters.zone.map((zone: { id: any }) => Number(zone.id)),
    };
  }
  if (filters.zone && filters.zone.length > 0) {
    filters = {
      ...filters,
      zone: filters.zone.map((zone: any) => Number(zone)),
    };
  }
  if (externalVoucherCampaign) {
    filters = {
      ...filters,
      [OfferFilters.TemplateType]: ['14'],
    };
  }

  const availableStatuses = [ApprovalStatus.Approved, ApprovalStatus.PendingApproval, ApprovalStatus.Draft];
  const { data, error, fetchMore } = useQuery(offersGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: offersSelection.getFilters({
          [OfferFilters.Zone]: [],
          [OfferFilters.TemplateType]: {},
          [OfferFilters.Tags]: {},
          [OfferFilters.SearchQuery]: '',
          ...filters,
          [OfferFilters.Status]:
            filters[OfferFilters.Status]?.length === 0 ? availableStatuses : filters[OfferFilters.Status],
        }),
        order: { id: OrderDirection.DESC },
        limit: 18,
      },
    },
  });
  const {
    getOffers: { items: offers, total },
  } = data || { getOffers: {} };
  useToastError(error, 'Error loading offers');

  const resetFilters = () => {
    store.dispatch(
      offersSelection.actions.setFilters({
        [OfferFilters.Status]: [ApprovalStatus.Approved],
        [OfferFilters.Zone]: filters[OfferFilters.Zone],
        [OfferFilters.TemplateType]: {},
        [OfferFilters.Tags]: {},
        [OfferFilters.SearchQuery]: '',
      }),
    );
  };

  const fetchNextOffers = async () => {
    if (offers.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: offersSelection.getFilters({
              [OfferFilters.Zone]: filters[OfferFilters.Zone],
              [OfferFilters.TemplateType]: {},
              [OfferFilters.Tags]: {},
              [OfferFilters.SearchQuery]: '',
              ...filters,
              [OfferFilters.Status]:
                filters[OfferFilters.Status]?.length === 0 ? availableStatuses : filters[OfferFilters.Status],
            }),
            offset: offers.length,
            limit: 18,
            order: { id: OrderDirection.DESC },
          },
        },
      });
    }
  };

  return (
    <StyledModal title="Offer Selection" withLoader={false}>
      <OfferSelectionContainer>
        <OfferSelectionFilterBar total={total} displayTemplate={!externalVoucherCampaign} />
        <OffersContainer id="offers-modal-scroll">
          {!offers ? (
            <StyledLoader size={LoaderSize.Large} />
          ) : (
            <>
              {total === 0 ? (
                <NoOffers>No Available Offers</NoOffers>
              ) : (
                <OffersList
                  scrollableTarget="offers-modal-scroll"
                  dataLength={offers.length}
                  next={fetchNextOffers}
                  hasMore={offers.length < total}
                  loader={<StyledLoader size={LoaderSize.Large} />}
                >
                  {offers?.map((offer: BaseOffer) => (
                    <StyledOfferItem
                      key={offer.id}
                      offer={offer.versions[0]}
                      isSelected={selected?.baseOfferId === offer.id}
                      onClick={() => setSelected(offer.versions[0])}
                    />
                  ))}
                </OffersList>
              )}
            </>
          )}
        </OffersContainer>
        <OfferSelectionFooter>
          <ButtonText
            onClick={() => {
              resetFilters();
              onCancel();
            }}
          >
            Cancel
          </ButtonText>
          <ButtonContained
            disabled={!selected}
            onClick={() => {
              resetFilters();
              onSave(selected);
            }}
          >
            Save
          </ButtonContained>
        </OfferSelectionFooter>
      </OfferSelectionContainer>
    </StyledModal>
  );
};

export default OfferSelection;
