import { convertUtcDateToTimezoneDate, getMarketDatetime } from 'utils/date';
import { ApprovalStatus, DateTimeConfig, FormMode } from 'utils/types';
import { Game } from 'utils/types/games';
import { VoucherState } from '../../CampaignForm.consts';
import { ValidationMessages } from 'utils/types/common';
import { hasValue } from 'utils/text';
import { isUndefinedOrEmpty } from 'utils/form';

export const validateCampaignStartDate = (
  date?: Date,
  mode?: FormMode,
  dateTimeConfig?: DateTimeConfig,
  selectedGame?: Game,
  isLocalCampaign?: any,
  selectedPeriodData?: any,
  voucherState?: VoucherState,
): string | undefined => {
  const currDateTime = date.getTime();
  const { startTimezone, endTimezone } = dateTimeConfig;
  const { isExternalVoucher, voucherDetails } = voucherState;
  if (Number.isNaN(currDateTime)) {
    return `Date is not valid`;
  }
  if (mode !== FormMode.View && currDateTime < getMarketDatetime(startTimezone).getTime()) {
    return `Start time is in the past`;
  }
  if (
    selectedGame &&
    currDateTime > new Date(convertUtcDateToTimezoneDate(selectedGame.endDateTime, endTimezone)).getTime()
  ) {
    return `Campaign Start Date must be before Game End Date`;
  }

  if (isLocalCampaign && selectedPeriodData) {
    if (
      date < convertUtcDateToTimezoneDate(selectedPeriodData.startDate, startTimezone) ||
      date > convertUtcDateToTimezoneDate(selectedPeriodData.endDate, endTimezone)
    ) {
      return 'Start Date must be within the selected period';
    }
  }
  if (
    isExternalVoucher &&
    voucherDetails &&
    currDateTime < new Date(convertUtcDateToTimezoneDate(voucherDetails.startAtUtc, startTimezone)).getTime()
  ) {
    return 'The start date is not within the Voucher dates';
  }
  return null;
};

export const validateCampaignEndDate = (
  date?: Date,
  mode?: FormMode,
  dateTimeConfig?: DateTimeConfig,
  selectedGame?: Game,
  campaignStart?: any,
  shouldValidateGameDates?: any,
  voucherState?: VoucherState,
): string | undefined => {
  const currTimestamp = date.getTime();
  const { startTimezone, endTimezone } = dateTimeConfig;
  const { isExternalVoucher, voucherDetails } = voucherState;
  const minDate = new Date(campaignStart).getTime();
  if (Number.isNaN(currTimestamp)) {
    return `Date is not valid`;
  }
  if (shouldValidateGameDates && mode === FormMode.View && currTimestamp < getMarketDatetime(endTimezone).getTime()) {
    return ``;
  }
  if (campaignStart && minDate >= currTimestamp) {
    return `End time must be after start time`;
  }
  if (currTimestamp < getMarketDatetime(endTimezone).getTime()) {
    return `End time is in the past`;
  }
  if (
    selectedGame &&
    currTimestamp < convertUtcDateToTimezoneDate(selectedGame.startDateTime, startTimezone).getTime()
  ) {
    return `Campaign End Date must be after Game Start Date`;
  }
  if (
    isExternalVoucher &&
    voucherDetails &&
    currTimestamp > new Date(convertUtcDateToTimezoneDate(voucherDetails.endAtUtc, endTimezone)).getTime()
  ) {
    return 'The end date is not within the Voucher dates';
  }
  return null;
};

export const validateGlobalLimit = (value: string, availableCount?: number): string | undefined => {
  if (!hasValue(value) && availableCount) {
    return ValidationMessages.RequiredField;
  }
  if (availableCount && Number(value) > availableCount) {
    return `The Global limit is more than the available limit`;
  }
  return undefined;
};

export const validateCustomerLifeTimeLimit = (
  value: string,
  globalLimit?: string | number,
  validate = false,
): string | undefined => {
  if (!hasValue(value) && validate) {
    return ValidationMessages.RequiredField;
  }
  if (Number(value) > Number(globalLimit)) {
    return 'The Customer Lifetime limit is more than the Global limit or available limit';
  }
  return undefined;
};

export const shouldTriggerGlobalLimitValidation = (
  globalLimit?: string | number,
  mode?: FormMode,
  status?: ApprovalStatus,
) => {
  return (
    !isUndefinedOrEmpty(String(globalLimit)) &&
    ([FormMode.New, FormMode.Duplicate].includes(mode) ||
      [ApprovalStatus.PendingApproval, ApprovalStatus.Draft, ApprovalStatus.Rejected].includes(status))
  );
};

export const shouldTriggerCampaignScheduleValidation = (mode?: FormMode, status?: ApprovalStatus) => {
  return (
    (mode === FormMode.View || mode === FormMode.Edit) &&
    [ApprovalStatus.PendingApproval, ApprovalStatus.Draft, ApprovalStatus.Rejected].includes(status)
  );
};

export const shouldValidateCamapignEndDate = (
  mode: FormMode,
  shouldValidateGameDates: boolean,
  isExternalVoucher: boolean,
) => {
  return mode !== FormMode.View || shouldValidateGameDates || isExternalVoucher;
};
